/* --Fonts-- */
/* Merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/merriweather-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/merriweather-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/merriweather-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/merriweather-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/merriweather-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/merriweather-v30-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* Share-tech-regular - latin */
@font-face {
  font-family: 'Share Tech';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/share-tech-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/share-tech-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/share-tech-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/share-tech-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/share-tech-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/share-tech-v17-latin-regular.svg#ShareTech') format('svg'); /* Legacy iOS */
}

:root {
  --hotmagenta: #ff00dd;
  --hotmagenta-light: hsl(308, 100%, 70%);
  --hotmagenta-dark: hsl(308, 100%, 10%);
  --aqua: #00FFFF;
  --alabaster: #F0EDE5;
  --jet: #333;
}

/* --CSS reset-- */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  background: #000;
  color: var(--alabaster);
}

/* -- General -- */

/* -- Home page -- */

body {
  font-family: 'Merriweather';
}

/*nav {
  display: flex;
  align-items: center;
}

.navbar {
  justify-content: flex-end;
  gap: 5rem;
  overflow: hidden;
  background-color: #000; 
  color: #333320;
  border-bottom: none;
  font-family: 'Share Tech';
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
}

.active {
  border-bottom: 4px solid var(--aqua);
}

.navbar a:hover {
  border-bottom: 4px solid var(--aqua);
} */

.postitem {
  background-color: #000;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
  padding: 1rem;
}

/* .postitem:last-child {
  border-bottom: none;
} */

.postitem img {
  height: 10rem;
}

.post-title {
  margin-bottom: 2.5rem;
}

.post-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-text {
  padding: 1rem;
}

.hero-text {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.hero-text img {
  height: 30rem;
}

.jc-flex-end {
  justify-content: flex-end;
}

a {
  text-decoration: none;
}

.contact-link:visited {
  color: inherit;
}

.neon-highlight {
  color: var(--hotmagenta);
}

/* --Projects page-- */

.grid-container-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  align-items: flex-start;
}

/* About me page */
.about-page {
  margin-inline: 0.8rem 0.8rem;
  line-height: 1.4rem;
}

.column {
  display: flex;
  gap: 0.8rem;
}

.question {
  font-weight: bold;
  font-size: 1.2rem;
  font-family: 'Share Tech';
  margin: 1rem 0 1rem 0;
}

.question::before {
  content: '[';
}

.question::after {
  content: ']';
}

h3 {
  padding: 0.5rem 0.5rem 0 0.5rem;
  color: var(--aqua);
}

h3::before, h3::after {
  content: '---';
}

.languages {
  display: flex;
  flex-wrap: wrap;

  text-align: center;
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.icon-container {
  width: 25%;
  height: 7rem;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.icon-container .icon {
  font-size: 3.5rem;
}

.colored-green {
  color: #01FF00;
}

.colored-blue {
  color: #0058F8;
}

.games-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.game-container img {
  height: 10rem;
}

.game-container p {
  padding-top: 0.5rem;
}

.games-container .game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width:768px) {
  .hero-text {
    flex-direction: column;
  }

  .hero-text img {
    height: unset;
  }

  /*switch out navbar*/

  .games-container {
    flex-direction: column;
  }

  .icon-container {
    font-size: 1rem;
  }

  p {
    text-align: justify;
  }

  .column {
    display: block;
  }
}