.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    font-size: 0.9rem;
}

.modal.show {
    opacity: 1;
    pointer-events: visible;
}

.modal-content {
    width: 500px;
    background-color: #000;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
}

.modal.show .modal-content {
    transform: translateY(0);
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}

.modal-body {
    padding: 10px;
    border-top: 1px solid var(--jet);
    border-bottom: 1px solid var(--jet);
}

.keyword-button {
    background: none;
    color: var(--hotmagenta);
    border: 1px solid var(--hotmagenta);
    font-size: 0.9rem;
    padding: 0.3em 0.55em;
    border-radius: 0.25em;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .modal-content {
        width: 300px;
    }
}