nav {
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
}

.closed-nav-height {
    height: 3rem;
}

.opened-nav-height {
    height: 15rem;
}

.navbar {
    justify-content: flex-end;
    gap: 5rem;
    overflow: hidden;
    background-color: #000;
    color: #333320;
    border-bottom: none;
    font-family: "Share Tech";
}

.navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
}

.active {
    border-bottom: 4px solid var(--aqua);
}

.navbar a:hover {
    border-bottom: 4px solid var(--aqua);
}

.list {
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5rem;
}

.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    font-size: 2rem;
    color: var(--aqua);
}

@media screen and (max-width: 768px) {
    .list {
        flex-direction: column;
        height: auto;
        gap: unset;
        position: relative;
        width: 100%;
    }

    .btn {
        display: block;
    }
}