.card {
  --padding: 1rem;
  border: 1px solid var(--jet);
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 2px hsla(308, 100%, 50%, 0.2);
  overflow: hidden;
}

.card-header {
  font-style: 1.5rem;
  padding: var(--padding);
  padding-bottom: 0;
  margin-bottom: 0.5rem;
  overflow: hidden;
}

.card-header > img {
  display: block;
  width: 100%;
  max-height: 200px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: center;
  transition: 200ms transform ease-in-out;
}

.card:hover > .card-header > img {
  transform: scale(1.025);
}

.card-body {
  font-style: 0.9rem;
  font-family: "Share Tech";
  padding: 0 var(--padding);
}

.card-description {
  font-family: "Merriweather";
}

.card-footer {
  margin-top: 1rem;
  padding: var(--padding);
  padding-top: 0;
  display: flex;
  justify-content: space-between;
}

.button {
  background: var(--hotmagenta);
  color: #000;
  border: none;
  font-size: 1rem;
  padding: 0.5em 0.75em;
  border-radius: 0.25em;
  cursor: pointer;
}

.button:hover,
.button:focus {
  background: var(--hotmagenta-light);
}

.button.button-outline {
  background: none;
  color: var(--hotmagenta);
  border: 1px solid var(--hotmagenta);
}

.button.button-outline:hover,
.button.button-outline:focus {
  background: var(--hotmagenta-dark);
}